<template>
  <v-app>
    <dashboard-core-app-bar />
    <dashboard-core-view />
    <dashboard-core-footer />

    <!-- <dashboard-core-settings /> -->
  </v-app>
</template>

<script>
  window.tInstance = null
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreView: () => import('./components/core/View'),
      DashboardCoreFooter: () => import('./components/core/Footer'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
